

import request from '@/utils/request';



const uploadFile = (data) => {
    return request({
        url: '/AwsFileUpload/s3_upload_multi',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data'
          }
    });	
}


const searchDeal= (data) => {
    data.types=['DEAL']
    return request({
        url: '/adm/search/auto_complete',
        method: 'post',
        data,
    });	
}


const searchAgent= (data) => {
    data.types=['AGENT']
    return request({
        url: '/adm/search/auto_complete',
        method: 'post',
        data,
    });	
}

const searchRegbvAgent = (data) => {

    return request({
        url: '/adm/search/brokerage_board_agent_api_search',
        method: 'post',
        data,
    });	

}


const searchFee= (data) => {
    return request({
        url: '/adm/config/brokerage/search_fee',
        method: 'post',
        data,
    });	
}

const searchExpense = (data) => {
    return request({
        url: '/adm/config/brokerage/search_expense',
        method: 'post',
        data,
    });	
    
}

const searchBoardAgent = (data) => {
    return request({
        url: '/adm/search/brokerage_board_agent_api_search',
        method: 'post',
        data,
    });	
    
}


const autoComplete =(data) => {
    return request({
        url: '/adm/search/auto_complete',
        method: 'post',
        data,
    });	
}

const search_lawyer =(data) => {
    return request({
        url: '/adm/search/brokerage_lawyer_search',
        method: 'post',
        data,
    });	
}

const search_agent_landlord = (str, agent_id) => {
    let data = {
        "types" : ["SEARCH_AGENT_LANDLORD"],
        "str" : str,
        "agent_id" : agent_id
    }

    return request({
        url: '/adm/search/auto_complete',
        method: 'post',
        data,
    });	
}

const search_agent_property = (str, agent_id) => {
    let data = {
        "types" : ["SEARCH_AGENT_PROPERTY"],
        "str" : str,
        "agent_id" : agent_id
    }

    return request({
        url: '/adm/search/auto_complete',
        method: 'post',
        data,
    });	
}

const search_accounting_gl = (str) => {
    let data = {
        "types" : ["SEARCH_ACCOUNTING_GL"],
        "str" : str,
    }

    return request({
        url: '/adm/search/auto_complete',
        method: 'post',
        data,
    });	
}

const place_auto_complete = (data) => {
    return request({
        url: '/adm/search/place_auto_complete',
        method: 'post',
        data,
    });	
}

const postal_code_query = (data) => {
    return request({
        url: '/adm/search/place_postal_code_query',
        method: 'post',
        data,
    });	
}


const search_pma_code = (str) => {
    let data = {
        "types" : ["SEARCH_PMA_CODE"],
        "str" : str
    }

    return request({
        url: '/adm/search/auto_complete',
        method: 'post',
        data,
    });	
}

const search_teams = (str) => {
    let data = {
        "types" : ["AGENT_TEAM"],
        "str" : str,
    }

    return request({
        url: '/adm/search/auto_complete',
        method: 'post',
        data,
    });	
}


const financial = (x, d) =>{
	return Number(Number.parseFloat(x).toFixed(d));
};




export {
   
    uploadFile,
    searchAgent,
    place_auto_complete,
    searchFee,
    autoComplete,
    financial,
    search_lawyer,
    searchBoardAgent,
    postal_code_query,
    searchExpense,
    search_agent_landlord,
    search_agent_property,
    search_accounting_gl,
    search_pma_code,
    searchRegbvAgent,
    searchDeal,
    search_teams
}